import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import DynamicSelectedGrid from './DisplayDynamicComponent/DynamicSelectedGrid';
import DynamicHighlights from './DisplayDynamicComponent/DynamicHighlights';
import FooterT from './DownFooter';
import { courses, HighlightsArray } from './HeroArrays';
import MapComponent from './Map';

const Allcourses = [
  {
    title: 'WATER TREATMENT / WATER UTILITY',
    qualification: 'Matric',
    fee: '22,000/-',
    duration: '6 Months',
    image: require('../newy/Water treatment by NF Nanofiltration method.jpeg'),
    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'BUILDING MANAGEMENT SYSTEM (BMS)',
    qualification: 'Matric',
    fee: '22,000/-',
    duration: '6 Months',
    image: require('../newy/A.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'ELECTRONIC AND COMMUNICATION',
    qualification: 'Matric',
    fee: '22,000/-',
    duration: '6 Months',
    image: require('../newy/3eFOwgdn.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'FIRE & SAFETY MANAGEMENT',
    qualification: 'Matric',
    fee: '22,000/-',
    duration: '6 Months',
    image: require('../newy/e9e3f2af-367e-4d17-9e54-2c22e64511a9.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'ADVANCE DIPLOMA IN FIRE & INDUSTRIAL SAFETY',
    qualification: 'Matric',
    fee: '22,000/-',
    duration: '6 Months',
    image: require('../compresspng/best trainer fo cee695c7-9264-467e-97dc-b81101ef56de-min.png'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'ADVANCE PLUMBING COURSE',
    qualification: 'Matric',
    fee: '25,000/-',
    duration: '6 Months',
    image: require('../newy/d989c1df-9623-49af-9ca8-8c78077b2b58.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'INSTRUMENTION',
    qualification: 'Matric',
    fee: '25,000/-',
    duration: '6 Months',
    image: require('../newy/Oil and gas, power workers stock photo_ Image of metal - 28500862.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: ' MEDICAL GAS TECHNICIAN',
    qualification: 'Matric',
    fee: '25,000/-',
    duration: '6 Months',
    image: require('../newy/Top Quality Oxygen Plant.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'ELECTRICAL & ELECTRONIC',
    qualification: 'Matric',
    fee: '25,000/-',
    duration: '6 Months',
    image: require('../newy/Proses.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'Diploma In Marketing Management',
    qualification: 'Matric',
    fee: '25,000/-',
    duration: '6 Months',
    image: require('../newly/How Paper Weight Affects Printing Results.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'Diploma In Computer Science',
    qualification: 'Matric',
    fee: '25,000/-',
    duration: '6 Months',
    image: require('../newly/Programmer Desk Setup.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
  {
    title: 'Diploma In Information Technology',
    qualification: 'Matric',
    fee: '25,000/-',
    duration: '6 Months',
    image: require('../newly/рабочий стол.jpeg'),

    modules: [
      'Work Shop Safety',
      'Refrigeration Tools',
      'Introduction of window AC, Split AC & HVAC',
      'Importance of HVAC',
      'Basic Refrigeration & Air Conditioning',
      'Refrigerator & window AC Operation & Servicing',
      'Water Cooler Operation & Servicing',
      'Main components of HVAC',
      'HVAC Controls',
      'Refrigerant Recovery & Recycling',
      'Operation of Cooling Tower AHU & FCU',
      'Refrigerant Handling & Property',
      'Electrical & Drawing reading of Chiller Package Unit',
    ],
  },
 
  // Add other courses here
];


export default function Pricing() {
  const [expanded, setExpanded] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleExpandClick = (courseTitle) => {
    setExpanded(expanded === courseTitle ? null : courseTitle);
  };

  const handleEnrollClick = (path) => {
    setLoading(true);
    setTimeout(() => {
      navigate(path, { replace: true });
      window.scrollTo(0, 0); // Scroll to the top of the page after navigation
      setLoading(false);
    }, 1000); // Simulate loading delay
  };

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        id="pricing"
        sx={(theme) => ({
          width: '100%',
          backgroundSize: '100% 20%',
          backgroundRepeat: 'no-repeat',
          pt: { xs: 15, sm: 22 },
          pb: { xs: 7, sm: 14 },
        })}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Box
            sx={{
              width: { sm: '100%', md: '60%' },
              textAlign: { sm: 'left', md: 'center' },
              mb: { xs: 1, sm: 1 },
            }}
          >
            <Typography component="h2" variant="h4" color="text.primary">
              Our Courses
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Discover our range of courses designed to provide you with the best education and practical skills. Enroll now and start your journey with us!
            </Typography>
          </Box>

          <Grid container spacing={4} sx={{ mt: { xs: 1, sm: 1 } }}>
            {Allcourses.map((course) => (
              <Grid item xs={12} sm={6} md={4} key={course.title}>
                <Card
                  sx={(theme) => ({
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    border: '1px solid',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: '#f5f5f5',
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.2)}`,
                    },
                  })}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={course.image}
                    alt={course.title}
                  />
                  <CardContent>
                    <Typography component="h3" variant="h6" color="text.primary">
                      {course.title}
                    </Typography>
                    <Typography component="p" variant="body2" color="text.secondary">
                      <strong>Qualification:</strong> {course.qualification}
                    </Typography>
                    <Typography component="p" variant="body2" color="text.secondary">
                      <strong>Course Fee:</strong> {course.fee}
                    </Typography>
                    <Typography component="p" variant="body2" color="text.secondary">
                      <strong>Course Duration:</strong> {course.duration}
                    </Typography>
                    <Button
                      onClick={() => handleExpandClick(course.title)}
                      aria-expanded={expanded === course.title}
                      aria-label="show more"
                      endIcon={<ExpandMoreIcon />}
                    >
                      {expanded === course.title ? 'Hide Description' : 'Show Description'}
                    </Button>
                    <Collapse in={expanded === course.title} timeout="auto" unmountOnExit>
                      <Box sx={{ mt: 2 }}>
                        <Typography component="p" variant="body2" color="text.primary">
                          <strong>Course Module:</strong>
                        </Typography>
                        <ul>
                          {course.modules.map((module, index) => (
                            <li key={index}>
                              <Typography component="p" variant="body2" color="text.secondary">
                                {module}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      </Box>
                    </Collapse>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => handleEnrollClick('/enroll')}
                    >
                      {loading ? <CircularProgress size={24} color="inherit" /> : 'Enroll Now'}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <DynamicSelectedGrid name={'Top Courses'} facilities={courses} />
      <DynamicHighlights
        title={'Highlights'}
        description={'Discover the key features and benefits of our institution. From top certifications to flexible timings and open admissions, explore what sets us apart.'}
        highlights={HighlightsArray}
      />
      <MapComponent/>
      <FooterT />
    </Box>
  );
}
