import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AppAppBar from './Navbar';
import Hero from './Components/Hero';
import StudentDetail from './Components/StudentDetails/StudentDetail';
import Pricing from './Components/CoursesEnroll';
import About from './Components/About/About';
import EnrollCourses from './Components/Courses/EnrollCourses';
import ContactUs from './Components/About/Contact';


import LoginForm from './Components/admin/Login';

import AddStudent from './Components/admin/AddStudent';
import SearchStudent from './Components/admin/GetStudent';
import AdminStudentDetail from './Components/admin/StudentDetailView';
import store from './Components/Slice/Store';
import { Provider } from 'react-redux';
import AdminRoute from './AdminRoute';
import SearchEnroolStudent from './Components/admin/GetEnroolStudent';

import LoadingScreen from './Components/Loading';


function App() {

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500); // Show loading screen for 3 seconds

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }
  return (

    <Provider store={store}>
      <Router>

        <AppAppBar />


        <Routes>
          <Route path='/' element={<Hero />} />
          <Route path='/verification' element={<StudentDetail />} />
          <Route path='/enroll' element={<EnrollCourses />} />
          <Route path='/about' element={<About />} />
          <Route path='/courses' element={<Pricing />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/adminLogin' element={<LoginForm />} />
          <Route element={<AdminRoute />}>
            <Route path='/addStudent' element={<AddStudent />} />
            <Route path='/getStudent' element={<SearchStudent />} />
            <Route path="/student/:studentId" element={<AdminStudentDetail />} />
            <Route path="/enrollStudent" element={<SearchEnroolStudent />} />
          </Route>

        </Routes>
      </Router>
    </Provider>

  );
}

export default App;

