import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { MoneyOff, LocationOn } from '@mui/icons-material';

export const pro1 = require('../New/0ac13cf1-5b3f-4338-940d-7add413c10e5.jpeg');
export const pro2 = require('../New/1363b556-354d-4dc9-87d2-44fe69b81793.jpeg');
export const pro3 = require('../New/1c687617-57cd-4bf7-b583-b5c63fb94cfd.jpeg');
export const pro4 = require('../New/21760aa6-a6b4-44ae-808d-002edbc0bc29.jpeg');
export const pro5 = require('../New/2256fbf7-d674-4ce4-801d-678d2f9b9568.jpeg');
export const pro6 = require('../New/725994a2-235d-426f-92f0-f54d7805b365.jpeg');

const placements = [
  {
    avatar: <Avatar alt="Company Logo" src={pro3} />,
    name: 'Mohammad Shihab Khan',
    trade: 'W W T P Operator',
    location: 'Al Kawtar S.K.A',
    salary: '1800 SR',
    description:
      "Mohammad Shihab Khan is working as a W W T P Operator at Al Kawtar S.K.A with a salary of 1800 SR.",
  },
  {
    avatar: <Avatar alt="Company Logo" src={pro1} />,
    name: 'Mohammad Yasir',
    trade: 'Pump Operator',
    location: 'Edgewater (Kahramaa)',
    salary: '2300 QR',
    description:
      "Mohammad Yasir is employed as a Pump Operator at Edgewater (Kahramaa) with a salary of 2300 QR.",
  },
  {
    avatar: <Avatar alt="Company Logo" src={pro2} />,
    name: 'Khan Adil Noor',
    trade: 'BMS Operator',
    location: 'UAE Abu Dhabi',
    salary: '1600 UAE Dirham',
    description:
      "Khan Adil Noor holds a position as a BMS Operator in UAE Abu Dhabi with a salary of 1600 UAE Dirham.",
  },
  {
    avatar: <Avatar alt="Company Logo" src={pro4} />,
    name: 'Asadullah Shaikh',
    trade: 'SR Utility Operator',
    location: 'SPIE Oil and Gas Qatar',
    salary: '3500 QR',
    description:
      "Asadullah Shaikh is serving as an SR Utility Operator at SPIE Oil and Gas Qatar, earning 3500 QR.",
  },
  {
    avatar: <Avatar alt="Company Logo" src={pro5} />,
    name: 'Ismail Ahmed',
    trade: 'HVAC Technician',
    location: 'Saudi Arabia',
    salary: '2700 SAR',
    description:
      "Ismail Ahmed is working as an HVAC Technician in Saudi Arabia with a salary of 2700 SAR.",
  },
  {
    avatar: <Avatar alt="Company Logo" src={pro6} />,
    name: 'Shoeb Khan',
    trade: 'Electrical Engineer',
    location: 'Kuwait',
    salary: '3200 KWD',
    description:
      "Shoeb Khan is employed as an Electrical Engineer in Kuwait with a salary of 3200 KWD.",
  },
];

const whiteLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function OurPlacement() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? whiteLogos : [];
  
  React.useEffect(() => {
    const handleScroll = () => {
      const cards = document.querySelectorAll('.placement-card');
      const windowHeight = window.innerHeight;
      const middleY = windowHeight / 2;
      
      cards.forEach(card => {
        const rect = card.getBoundingClientRect();
        const cardCenterY = rect.top + (rect.height / 2);
        
        if (cardCenterY > middleY - 100 && cardCenterY < middleY + 100) {
          card.classList.add('centered');
        } else {
          card.classList.remove('centered');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <>
      <Box sx={{ mb: 4, p: 2, mt: 4, backgroundColor: 'primary.light', width: '100%' }}>
        <Typography component="h2" variant="h4" sx={{ color: 'white', textAlign: 'center' }}>
          <span>Our Placement</span>
        </Typography>
      </Box>
      <Container
        id="placement"
        sx={{
          pt: { xs: 1, sm: 2 },
          pb: { xs: 2, sm: 8 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography variant="body1" color="text.secondary">
            Discover the companies where our graduates have secured placements. Our
            placement record reflects a 100% placement rate, with graduates thriving
            in diverse roles across leading organizations.
          </Typography>
        </Box>
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
          {placements.map((placement, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                className="placement-card"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 2,
                  boxShadow: '0 0 5px rgba(173, 216, 230, 0.8)',
                  transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0 0 10px rgba(173, 216, 230, 0.8)',
                    transform: 'scale(1.05)',
                  },
                  '@media (max-width: 600px)': {
                    transform: 'scale(1)',
                    '&.centered': {
                      transform: 'scale(1.1)',
                    },
                  },
                }}
              >
                <CardHeader
                  avatar={placement.avatar}
                  title={placement.name}
                  subheader={placement.trade}
                  sx={{ borderBottom: '1px solid #ddd' }} // Add border between image and content
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    {placement.description}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <LocationOn sx={{ mr: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      {placement.location}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <MoneyOff sx={{ mr: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                      {placement.salary}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
