import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CircularProgress from '@mui/material/CircularProgress';

export default function DynamicSelectedGrid({ name, facilities }) {
  const [selectedFacilityIndex, setSelectedFacilityIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoading(true);
      setTimeout(() => {
        setSelectedFacilityIndex((prevIndex) => (prevIndex + 1) % facilities.length);
        setLoading(false);
      }, 500);
    }, 5000); // Change facility every 5 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [facilities.length]);

  const validIndex = Math.max(0, Math.min(selectedFacilityIndex, facilities.length - 1));
  const selectedFacility = facilities[validIndex];

  const handleFacilityClick = (index) => {
    if (index !== selectedFacilityIndex) {
      setLoading(true);
      setTimeout(() => {
        setSelectedFacilityIndex(index);
        setLoading(false);
      }, 500);
    }
  };

  const handleLearnMoreClick = () => {
    setLoading(true);
    setTimeout(() => {
      navigate('/about');
      window.scrollTo(0, 0);
      setLoading(false);
    }, 500);
  };

  return (
    <>
      <Box sx={{ mb: 4, p: 2, mt: 4, backgroundColor: 'primary.light', width: '100%' }}>
        <Typography component="h2" variant="h4" sx={{ color: 'white', textAlign: 'center' }}>
          <span>Our </span>
          {name}
        </Typography>
      </Box>
      <Box
        id="facilities"
        sx={{
          width: '100%',
          px: { xs: 0, sm: 2 },
          py: { xs: 4, sm: 5 },
          backgroundColor: 'background.default',
        }}
      >
        <Container maxWidth="xl" sx={{ width: { xs: '100%', lg: '90%' } }}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" color="text.secondary" sx={{ mb: { xs: 2, sm: 4 } }}>
                Discover the facilities we offer to enhance your learning experience.
              </Typography>

              <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
                {facilities.map(({ title }, index) => (
                  <Chip
                    key={index}
                    label={title}
                    onClick={() => handleFacilityClick(index)}
                    sx={{
                      borderColor: validIndex === index ? 'primary.light' : '',
                      backgroundColor: validIndex === index ? 'primary.main' : '',
                      color: validIndex === index ? '#fff' : '',
                      borderRadius: 2,
                      boxShadow: validIndex === index ? '0 0 5px rgba(173, 216, 230, 0.8)' : '',
                      transition: 'box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        backgroundColor: validIndex === index ? 'primary.dark' : '',
                        boxShadow: '0 0 10px rgba(173, 216, 230, 0.8)',
                      },
                    }}
                  />
                ))}
              </Grid>

              <Box
                component={Card}
                variant="outlined"
                sx={{
                  display: { xs: 'auto', sm: 'none' },
                  mt: 4,
                  borderRadius: 2,
                  boxShadow: `0 0 5px rgba(173, 216, 230, 0.8)`,
                  transition: 'box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: `0 0 10px rgba(173, 216, 230, 0.8)`,
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    backgroundImage: `url(${selectedFacility?.imageLight})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: { xs: 200, sm: 280 },
                    borderRadius: 2,
                    boxShadow: `0 0 5px rgba(173, 216, 230, 0.8)`,
                    transition: 'box-shadow 0.3s ease-in-out, transform 0.5s ease-in-out',
                    '&:hover': {
                      boxShadow: `0 0 10px rgba(173, 216, 230, 0.8)`,
                      transform: 'scale(1.05)',
                    },
                    overflow: 'hidden',
                  }}
                >
                  {loading && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 10,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
                <Box sx={{ px: 2, pb: 2 }}>
                  <Typography color="text.primary" variant="body2" fontWeight="bold">
                    {selectedFacility?.title}
                  </Typography>
                  <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                    {selectedFacility?.description}
                  </Typography>
                  <Link
                    color="primary"
                    variant="body2"
                    fontWeight="bold"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      '& > svg': { transition: '0.2s' },
                      '&:hover > svg': { transform: 'translateX(2px)' },
                    }}
                    onClick={handleLearnMoreClick}
                  >
                    <span>Learn more</span>
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      sx={{ mt: '1px', ml: '2px' }}
                    />
                  </Link>
                </Box>
              </Box>

              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                useFlexGap
                sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
              >
                {facilities.map(({ icon, title, description }, index) => (
                  <Card
                    key={index}
                    variant="outlined"
                    component={Button}
                    onClick={() => handleFacilityClick(index)}
                    sx={{
                      p: 3,
                      height: 'fit-content',
                      width: '100%',
                      background: 'none',
                      backgroundColor:
                        validIndex === index ? 'action.selected' : undefined,
                      borderColor: validIndex === index ? 'primary.light' : 'grey.200',
                      borderRadius: 2,
                      boxShadow: validIndex === index ? '0 0 5px rgba(173, 216, 230, 0.8)' : '',
                      transition: 'box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.5s ease-in-out',
                      '&:hover': {
                        backgroundColor: 'action.hover',
                        boxShadow: '0 0 10px rgba(173, 216, 230, 0.8)',
                        transform: 'scale(1.02)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        textAlign: 'left',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { md: 'center' },
                        gap: 2.5,
                      }}
                    >
                      <Box
                        sx={{
                          color: validIndex === index ? 'primary.main' : 'grey.300',
                        }}
                      >
                        {icon}
                      </Box>
                      <Box sx={{ textTransform: 'none' }}>
                        <Typography color="text.primary" fontWeight="bold">
                          {title}
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                          {description}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                ))}
              </Stack>
            </Grid>

            <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Box
                component={Card}
                variant="outlined"
                sx={{
                  height: '100%',
                  borderRadius: 2,
                  boxShadow: `0 0 5px rgba(173, 216, 230, 0.8)`,
                  transition: 'box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: `0 0 10px rgba(173, 216, 230, 0.8)`,
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    backgroundImage: `url(${selectedFacility?.imageLight})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '100%',
                    minHeight: '100%',
                    borderRadius: 2,
                    boxShadow: `0 0 5px rgba(173, 216, 230, 0.8)`,
                    transition: 'box-shadow 0.3s ease-in-out, transform 0.5s ease-in-out',
                    '&:hover': {
                      boxShadow: `0 0 10px rgba(173, 216, 230, 0.8)`,
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  {loading && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 10,
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
                <Box sx={{ px: 2, pb: 2 }}>
                  <Typography color="text.primary" variant="body2" fontWeight="bold">
                    {selectedFacility?.title}
                  </Typography>
                  <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                    {selectedFacility?.description}
                  </Typography>
                  <Link
                    color="primary"
                    variant="body2"
                    fontWeight="bold"
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      '& > svg': { transition: '0.2s' },
                      '&:hover > svg': { transform: 'translateX(2px)' },
                    }}
                    onClick={handleLearnMoreClick}
                  >
                    <span>Learn more</span>
                    <ChevronRightRoundedIcon
                      fontSize="small"
                      sx={{ mt: '1px', ml: '2px' }}
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
