import React, { useState } from 'react';
import Slider from 'react-slick';
import { Typography, Button, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ImageWrapper = styled('div')({
  textAlign: 'center',
  padding: '10px',
  boxSizing: 'border-box',
  position: 'relative',
  '& img': {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '10px',
    transition: 'transform 0.3s ease, filter 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      filter: 'brightness(80%)',
    },
  },
  '&.slick-center img': {
    filter: 'brightness(100%)',
    transform: 'scale(1.1)',
  },
  '&.slick-slide:not(.slick-center) img': {
    filter: 'brightness(50%)',
    transform: 'scale(0.9)',
  },
});

const courses = [
  { name: "WATER TREATMENT / WATER UTILITY", image: require('../../newy/Water treatment by NF Nanofiltration method.jpeg'), fee: "25,000" },
  { name: "BUILDING MANAGEMENT SYSTEM (BMS)", image: require('../../images/bms.png'), fee: "25,000" },
  { name: "ELECTRICAL & ELECTRONIC", image: require('../../newy/Free Photo _ Man an electrical technician working in a switchboard with fuses.jpeg'), fee: "20,000" },
  { name: "FIRE & SAFETY MANAGEMENT", image: require('../../images/fire1.png'), fee: "20,000" },
  { name: "ADVANCE DIPLOMA IN FIRE & INDUSTRIAL SAFETY", image: require('../../newy/Untitled.jpeg'), fee: "28,000" },
  { name: "ADVANCE PLUMBING", image: require('../../images/plum.png'), fee: "15,000" },
  { name: "HVAC & REFRIGERATION", image: require('../../classImages/hv.jpg'), fee: "22,000" },
  { name: "INSTRUMENTATION", image: require('../../compresspng/works in mechan 219172ce-6e3b-433a-aed1-0c59d5b78c1e-min.png'), fee: "25,000" },
  { name: "Diploma In Computer Science", image: require('../../newly/рабочий стол.jpeg'), fee: "28,000" },
  { name: "Diploma In Information Technology", image: require('../../newly/Industrial Automation Unites the Best of OT and IT.jpeg'), fee: "26,000" },
  { name: "Diploma In Marketing Management", image: require('../../newly/Workplace stock photo_ Image of charts, diagram, busy - 24738634.jpeg'), fee: "28,000" }
];

const CourseCarousel = () => {
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Use navigate hook

  const handleEnrollClick = () => {
    setLoading(true); // Show spinner
    // Delay navigation to ensure spinner is visible
    setTimeout(() => {
      window.scrollTo(0, 0);
      navigate('/enroll'); // Navigate to /enroll
    }, 300); // Adjust timeout as needed
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    focusOnSelect: true,
    arrows: false,
    autoplay: true, // Enable automatic transitions
    autoplaySpeed: 2000, // Transition speed in milliseconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerPadding: "40px",
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerPadding: "30px",
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "20px",
        }
      }
    ]
  };

  return (
    <>
      <Box sx={{ mb: 4, p: 2, mt: 4, backgroundColor: 'primary.light', width: '100%' }}>
        <Typography component="h2" variant="h4" sx={{ color: 'white', textAlign: 'center' }}>
          <span>Our Courses</span>
        </Typography>
      </Box>

      <div className="slider-container">
        <Slider {...settings}>
          {courses.map((course, index) => (
            <ImageWrapper key={index}>
              <img src={course.image} alt={course.name} />
              <Typography
                variant="h6"
                sx={{
                  marginTop: 2,
                  fontSize: {
                    xs: '1.2rem', // For extra small screens
                    sm: '1.4rem', // For small screens
                    md: '1.6rem', // For medium screens
                    lg: '1.8rem', // For large screens
                    xl: '2rem', // For extra large screens
                  }
                }}
              >
                {course.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: 'text.secondary',
                  fontSize: {
                    xs: '0.9rem', // For extra small screens
                    sm: '1rem',   // For small screens
                    md: '1.2rem', // For medium screens
                    lg: '1.4rem', // For large screens
                    xl: '1.6rem', // For extra large screens
                  }
                }}
              >
                <span style={{ verticalAlign: 'middle' }}>₹</span> {/* Replace with rupee symbol if needed */}
                {course.fee}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleEnrollClick} // Use handleEnrollClick
                sx={{ marginTop: 2 }}
              >
                {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Enroll'}
              </Button>
            </ImageWrapper>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default CourseCarousel;
