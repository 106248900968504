import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

export default function AboutUsPanel() {
  const images = require('../../images/banner2.png');
  const [isCentered, setIsCentered] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const imageElement = document.querySelector('#about-us-img');
      if (imageElement) {
        const rect = imageElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const middleY = windowHeight / 2;

        const imageCenterY = rect.top + (rect.height / 2);

        if (imageCenterY > middleY - 100 && imageCenterY < middleY + 100) {
          setIsCentered(true);
        } else {
          setIsCentered(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <Box
      id="about-us"
      sx={{
        width: '100%',
        px: { xs: 2, sm: 4 },
        py: { xs: 4, sm: 6 },
        backgroundColor: 'background.default',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h6"
          component="h3"
          sx={{
            mb: 4,
            mr: 2,
            color: 'text.primary',
            textAlign: 'center',
            fontWeight: '500',
            fontSize: { xs: '1rem', sm: '1.2rem' } // Adjust font size based on screen size
          }}
        >
          Best Technical Institute in Raipur | Best Safety Training Institute in Raipur
        </Typography>
        <Grid container spacing={4} alignItems="center" direction={{ xs: 'column', md: 'row' }}>
          <Grid item xs={12} md={5} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Box
              id="about-us-img"
              component="img"
              src={images}
              alt="Best Technical Institute in Jamshedpur | Best Safety Training Institute in Jamshedpur"
              sx={{
                width: '80%',
                height: 'auto',
                borderRadius: 2,
                mb: { xs: 2, md: 0 },
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out', // Transition for smooth effect
                transform: isCentered ? 'scale(1.1)' : 'scale(1)', // Scale up if centered
                boxShadow: isCentered ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none', // Add shadow if centered
                '@media (max-width: 600px)': {
                  transform: isCentered ? 'scale(1.2)' : 'scale(1)', // More scaling on small screens
                },
              }}
            />
          </Grid>
          {/* Divider (Vertical Line) */}
          <Grid item xs={12} md={1} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            <Divider orientation="vertical" sx={{ height: '100%', borderRightWidth: 2 }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                mr: 4,
                fontSize: { xs: '0.9rem', md: '1.1rem' }, // Adjust font size based on screen size
                lineHeight: 1.6,
                fontWeight: '400',
                textAlign: { xs: 'center', md: 'left' }
              }}
            >
              At{' '}
              <span style={{ color: '#39a7cc', fontWeight: 'bold' }}>
                Genius Industrial Training Institute and Management
              </span>, we are committed to delivering high-quality training that equips students with industry-relevant skills while fostering values of integrity and excellence. Our specialized courses span key fields such as Water Treatment, Electrical & Electronics, and Building Management, preparing students for long-term success in competitive industries.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
