import React, { useState } from 'react';
import {
  Box, Button, Container, Grid, TextField, Typography, MenuItem,
  Stepper, Step, StepLabel, Snackbar, Alert
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DynamicHighlights from '../DisplayDynamicComponent/DynamicHighlights';
import { HighlightsArray } from '../HeroArrays';
import FooterT from '../DownFooter';
import axios from 'axios';
import MapComponent from '../Map';

const courses = [
  { value: 'Water Treatment', label: 'Water Treatment' },
  { value: 'Building Management System', label: 'Building Management System' },
  { value: 'Electronic & Communication', label: 'Electronic & Communication' },
  { value: 'Fire And Safety Management', label: 'Fire And Safety Management' },
  { value: 'Electrcal & Electronic', label: 'Electrcal & Electronic' },
  { value: 'Advance Deplomona In Fire & Industrial Safety', label: 'Advance Deplomona In Fire & Industrial Safety' },
  { value: 'Advance Plumbing Course', label: 'Advance Plumbing Course' },
  { value: 'Instrumentation', label: 'Instrumentation' },
  { value: 'Medical Gas Techncian', label: 'Medical Gas Techncian' },
];  

const steps = ['Personal Details', 'Academic Qualifications', 'Course Selection', 'Confirmation', 'Success'];

const EnrollCourses = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    dob: '',
    age: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    qualification: '',
    course: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setOpenSnackbar(true);
    try {
      await axios.post('http://localhost:8080/enrool/add', formData)
      setActiveStep(steps.length - 1); // Move to the "Success" step


      setTimeout(() => {
        // Reset to the first step after 1 second
        setActiveStep(0);
        setFormData({
          name: '',
          dob: '',
          age: '',
          phone: '',
          email: '',
          address: '',
          city: '',
          state: '',
          pincode: '',
          qualification: '',
          course: '',
        });
      }, 3000);

    } catch (error) {
      alert('Error Please Try Again')
    }

  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Date of Birth"
                variant="outlined"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                type="date"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Age"
                variant="outlined"
                name="age"
                value={formData.age}
                onChange={handleChange}
                type="number"
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                variant="outlined"
                name="address"
                value={formData.address}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                name="city"
                value={formData.city}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="State"
                variant="outlined"
                name="state"
                value={formData.state}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Pincode"
                variant="outlined"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Academic Qualification"
                variant="outlined"
                name="qualification"
                value={formData.qualification}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Select Course"
                variant="outlined"
                name="course"
                value={formData.course}
                onChange={handleChange}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '8px',
                  },
                }}
              >
                {courses.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="h5" gutterBottom>
              Please Confirm Your Details
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Name:
                </Typography>
                <Typography variant="body1">{formData.name}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Date of Birth:
                </Typography>
                <Typography variant="body1">{formData.dob}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Age:
                </Typography>
                <Typography variant="body1">{formData.age}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Phone:
                </Typography>
                <Typography variant="body1">{formData.phone}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Email:
                </Typography>
                <Typography variant="body1">{formData.email}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Address:
                </Typography>
                <Typography variant="body1">{formData.address}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  City:
                </Typography>
                <Typography variant="body1">{formData.city}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  State:
                </Typography>
                <Typography variant="body1">{formData.state}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Pincode:
                </Typography>
                <Typography variant="body1">{formData.pincode}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Qualification:
                </Typography>
                <Typography variant="body1">{formData.qualification}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Course:
                </Typography>
                <Typography variant="body1">{formData.course}</Typography>
              </Grid>
            </Grid>
          </Box>
        );
      case 4:
        return (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography variant="h4" color="success.main">
              Enrollment Successful!
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              You will receive a confirmation message soon.
            </Typography>
          </Box>
        );
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'relative', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '30px' }}>
        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            Enrollment successful! You will receive a confirmation message soon.
          </Alert>
        </Snackbar>
        <Container sx={{ zIndex: 1, textAlign: 'center' }}>
          <Typography variant="h4" sx={{ mb: 4, color: 'primary.main', mt: 10 }}>
            Enroll in a Course
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 4 }}>
            {renderStepContent(activeStep)}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              {activeStep !== 0 && activeStep !== steps.length - 1 && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ArrowBackIcon />}
                  onClick={handleBack}
                >
                  Previous
                </Button>
              )}
              {activeStep === steps.length - 2 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  endIcon={<ArrowForwardIcon />}
                >
                  Submit
                </Button>
              ) : activeStep !== steps.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  endIcon={<ArrowForwardIcon />}
                >
                  Next
                </Button>
              ) : null}
            </Box>
          </Box>
        </Container>
      </Box>
      <DynamicHighlights
        title="Highlights"
        description="Discover the key features and benefits of our institution. From top certifications to flexible timings and open admissions, explore what sets us apart."
        highlights={HighlightsArray}
      />
      <MapComponent/>
      <FooterT />
    </Box>
  );
};

export default EnrollCourses;
