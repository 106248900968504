import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  Divider,
  CircularProgress,
} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import VerifiedIcon from '@mui/icons-material/Verified';
import SchoolIcon from '@mui/icons-material/School';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeIcon from '@mui/icons-material/Home';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate, useLocation } from 'react-router-dom';

const footerLinks = [
  { name: 'Contact Us', path: '/contactus', icon: <PhoneIcon /> },
  { name: 'About Us', path: '/about', icon: <SchoolIcon /> },
  { name: 'Verification', path: '/verification', icon: <VerifiedIcon /> },
  { name: 'Courses', path: '/courses', icon: <SchoolIcon /> },
  { name: 'Enroll Now', path: '/enroll', icon: <PersonAddIcon /> },
  { name: 'Director Message', path: '/about', icon: <SchoolIcon /> },
  { name: 'Home', path: '/', icon: <HomeIcon /> },
  { name: 'Admin', path: '/adminlogin', icon: <AdminPanelSettingsIcon /> },
];

function FooterT() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const logo = require('../images/banner2.png');
  const aboutUsRef = useRef(null);
  const [underlineVisible, setUnderlineVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (aboutUsRef.current) {
        const rect = aboutUsRef.current.getBoundingClientRect();
        setUnderlineVisible(rect.top >= 0 && rect.bottom <= window.innerHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavigate = (path) => {
    setLoading(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
      navigate(path);
      setLoading(false);
    }, 500);
  };

  const getLinkStyle = (path) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#ffffff',
    fontWeight: location.pathname === path ? 'bold' : 'normal',
    textDecoration: 'none',
    '&:hover': { color: '#81d4fa', textDecoration: 'underline' }, // Pale blue hover effect
  });

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: '#000000',
        color: '#ffffff',
        py: 4,
        borderTop: '4px solid #d3d3d3',
        mt: 8,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="flex-start">
          {/* About Us Section (Left) */}
          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                color: '#ffffff',
                position: 'relative',
              }}
              ref={aboutUsRef}
            >
              About Us
              <Divider
                sx={{
                  mt: 2,
                  borderColor: '#81d4fa',
                  height: '2px',
                  width: '50%',
                  mx: 'auto',
                  position: 'absolute',
                  bottom: '-10px',
                  left: '0',
                  right: '0',
                  transform: underlineVisible ? 'scaleX(1)' : 'scaleX(0)',
                  transition: 'transform 0.5s ease-in-out',
                }}
              />
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: 1.6, color: '#e0e0e0' }}>
              We at Genius Industrial Training Institute And Management have the courage to carry forth our belief during the battle of business with our tolerance & innovation and also resist various temptations to a quicker monetary result; however, we do not compromise our core values for which we stand.
            </Typography>
          </Grid>

          {/* Logo and Social Icons (Center) */}
          <Grid item xs={12} md={4} textAlign="center">
            <img
              src={logo}
              alt="Institute Logo"
              style={{ width: '200px', marginBottom: '1rem', cursor: 'pointer' }}
              onClick={() => handleNavigate('/')}
            />
            <Box display="flex" justifyContent="center" mt={2}>
              {[
                { icon: <InstagramIcon />, href: 'https://instagram.com' },
                { icon: <FacebookIcon />, href: 'https://facebook.com' },
                { icon: <TwitterIcon />, href: 'https://twitter.com' },
                { icon: <PhoneIcon />, href: 'tel:+123456789' },
                { icon: <EmailIcon />, href: 'mailto:info@institute.com' },
              ].map(({ icon, href }, index) => (
                <IconButton
                  key={index}
                  aria-label={href}
                  sx={{ color: '#ffffff', mx: 1, '&:hover': { color: '#81d4fa' } }} // Pale blue hover effect
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {icon}
                </IconButton>
              ))}
            </Box>
          </Grid>

          {/* Footer Links (Right) */}
          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'right' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center" mb={{ xs: 2, md: 0 }}>
                  {footerLinks.slice(0, 4).map((link) => (
                    <Link
                      key={link.name}
                      onClick={() => handleNavigate(link.path)}
                      sx={getLinkStyle(link.path)}
                      mb={2}
                    >
                      {link.icon} <Typography variant="body1" sx={{ ml: 1, color: '#ffffff' }}>{link.name}</Typography>
                    </Link>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  {footerLinks.slice(4).map((link) => (
                    <Link
                      key={link.name}
                      onClick={() => handleNavigate(link.path)}
                      sx={getLinkStyle(link.path)}
                      mb={2}
                    >
                      {link.icon} <Typography variant="body1" sx={{ ml: 1, color: '#ffffff' }}>{link.name}</Typography>
                    </Link>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Divider and Additional Info */}
        <Divider sx={{ my: 4, borderColor: '#1565c0' }} />
        <Box textAlign="center">
          <Typography variant="body2" sx={{ color: '#e0e0e0' }}>
            Empowering Education for a Brighter Future
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, color: '#e0e0e0' }}>
            &copy; 2024 Genius Institute &nbsp; <span style={{ fontSize: '1.2rem' }}>∞</span> &nbsp; Made by Infinite Corporation
          </Typography>
        </Box>
      </Container>

      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 1200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default FooterT;
