import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography, CardContent, Card } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DynamicHighlights from '../DisplayDynamicComponent/DynamicHighlights';
import { VerificationHighlights } from '../HeroArrays';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import FooterT from '../DownFooter';
import axios from 'axios';
import MapComponent from '../Map';



const benefits = [
  {
    icon: <ThumbUpAltRoundedIcon fontSize="large" />,
    title: 'Best Training',
    description: 'Genius Institute is giving 100% practical training for all the students.',
  },
  {
    icon: <VerifiedRoundedIcon fontSize="large" />,
    title: 'Best Trainers',
    description: 'We have Best Trainers with 10 years of Industry experience.',
  },
  {
    icon: <SupportAgentRoundedIcon fontSize="large" />,
    title: 'Advanced Learning',
    description: 'For the students we have High configuration systems.',
  },
  {
    icon: <HomeRepairServiceRoundedIcon fontSize="large" />,
    title: 'Lab Support',
    description: 'We are providing any time Lab Facility for the students.',
  },
];
const downloadCertificate = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/download-certificate/${id}`, {
      responseType: 'blob', // Important to handle binary data
    });
    
    // Create a URL and trigger the download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'certificate.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading the certificate:', error);
  }
};
const StudentDetail = () => {
  const [studentId, setStudentId] = useState('');
  const [studentData, setStudentData] = useState(null);
  const [passportPhoto,setPassportPhoto] = useState(null)
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState('Search');

  const findStudent = (id) => {
    setLoading(true);
    setTimeout(() => {
      axios.get(`${process.env.REACT_APP_API_URL}/students/${id}`)
      .then(response => {
        if (response.data) {
          setStudentData(response.data);
          fetchPassportPhoto(id)
          setButtonText('Search'); // Reset button text
        } else {
          setButtonText('Student not found');
          setStudentData(null);
        }
      })
      .catch(error => {
          console.error("There was an error fetching the students!", error);
          setLoading(false);
          setButtonText('Student Not Found');
      });

      setLoading(false);
    }, 1000);
  };
  const fetchPassportPhoto = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/photo/${id}`, {
        responseType: 'blob' // Important to handle binary data
      });
      // Convert the blob to a URL and store it in state
      const photoUrl = URL.createObjectURL(response.data);
      setPassportPhoto(photoUrl);
    } catch (error) {
      console.error('Error fetching the passport photo:', error);
    }
  };

  const handleSearch = () => {
    if (studentId.trim()) {
      setButtonText('Loading...');
      findStudent(studentId.trim());
    } else {
      setButtonText('Please enter a valid student ID');
    }
  };

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
        backgroundSize: '80% 20%',
        backgroundRepeat: 'no-repeat',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: theme =>
            theme.palette.mode === 'light' ? 'linear-gradient(180deg, #CEE5FD, #FFF)' : 'linear-gradient(#02294F, #090E10)',
          py: { xs: 15, sm: 12 }
          
        }}
      >
        <Container sx={{ zIndex: 1, textAlign: 'center' }}>
          {!studentData ? (
            <>
              <Typography variant="h4" sx={{ mb: 4, color: 'primary.main' }}>
                Find Your Student Details
              </Typography>
              <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
                Enter the Registration Number below to view details and download the certificate.
              </Typography>
              <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    label="Registration Number"
                    variant="outlined"
                    value={studentId}
                    onChange={(e) => {
                      setStudentId(e.target.value);
                      setButtonText('Search');
                    }}
                    InputProps={{
                      style: {
                        backgroundColor: 'white',
                        borderRadius: '8px',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    disabled={loading}
                    sx={{ height: '56px' }}
                  >
                    {buttonText}
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={4} mt={'20px'}>
                {benefits.map(({ icon, title, description }, index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 2,
                        boxShadow: `0 0 5px ${'#CEE5FD'}`,
                        backgroundColor: 'white',
                        p: 2,
                        border: `1px solid transparent`,
                        '&:hover': {
                          boxShadow: `0 0 15px ${'#CEE5FD'}`,
                          transform: 'scale(1.05)',
                          transition: '0.3s',
                          borderColor: '#CEE5FD',
                        },
                      }}
                    >
                      <Box sx={{ mb: 2 }}>{icon}</Box>
                      <CardContent>
                        <Typography variant="h6" color="text.primary">
                          {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <>
            <Typography variant="h4" sx={{ mb: 4, color: 'primary.main' }}>
                Find Your Student Details
              </Typography>
            <Box sx={{ bgcolor: 'background.paper', p: 4, borderRadius: 2, }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  setStudentData(null);
                  setButtonText('Search'); // Reset button text
                }}
                sx={{ mb: 4 }}
              >
                Back to Search
              </Button>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ position: 'relative' }}>
                    <Box
                      component="img"
                      src={require('../../classImages/gulf.png')} // Placeholder image
                      alt="Student Background"
                      sx={{ width: '100%', borderRadius: 2 }}
                    />
                    <Box
                        component="img"
                        src={passportPhoto} // Use the state variable for the photo
                        alt="Student"
                        sx={{
                          width: 120,
                          height: 120,
                          borderRadius: '50%',
                          border: '4px solid white',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          objectFit: 'cover', // Ensure the image fits within the circular frame
                        }}

                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" color="primary" sx={{ mt: 2 }}>
                    {studentData.firstName} 
                    &nbsp;
                    {studentData.lastName} 
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>Age: {studentData.age}</Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>Admission Year: {studentData.admissionYear}</Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>Pass Year: {studentData.passYear}</Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>Course: {studentData.course}</Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>City: {studentData.address}</Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>Roll No: {studentData.rollno}</Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>Grade: A</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => downloadCertificate(studentData.studentId)}
                    sx={{ mt: 2 }}
                  >
                    Download Certificate
                  </Button>
                </Grid>
              </Grid>
            </Box>
            </>
          )}
        </Container>
      </Box>
      
      <DynamicHighlights
        title={'Verification Highlights'}
        description={'Explore the key features of our verification process. From secure access to instant downloads, learn how we ensure a smooth and protected experience for our students.'}
        highlights={VerificationHighlights}
      />
      <MapComponent/>
      <FooterT />
    </Box>
  );
};

export default StudentDetail;
