import * as React from 'react';
import { CircularProgress, Typography, Container, Box } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';

function LoadingScreen() {
  const logo = require('../classImages/finallogo.png');

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        px: 2,
        textAlign: 'center',
      }}
    >
      <Box
        component="img"
        src={logo}
        alt="Genius Institute Logo"
        sx={{
          width: { xs: 80, sm: 100 },
          height: 'auto',
          mb: 2,
        }}
      />
      <Typography
        variant="h4"
        component="h1"
        sx={{
          mb: 2,
          fontWeight: 'bold',
          fontSize: { xs: '1.5rem', sm: '2rem' },
          lineHeight: 1.2,
        }}
      >
        Genius Industrial Training Institute and Management
      </Typography>
      <CircularProgress sx={{ mt: 2 }} />
    </Container>
  );
}

export default LoadingScreen;
