import React from 'react';
import { Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Slider from 'react-slick';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import images
export const pro1 = require('../../New/7d59d9c8-11bc-473f-bc61-0ee749617a0b.jpeg');
export const pro2 = require('../../New/803af8c5-7568-48a0-b5ec-214ac54e028f.jpeg');
export const pro3 = require('../../New/99995e0d-83d9-4541-8f0c-19f57e2cb34d.jpeg');
export const pro4 = require('../../New/536e1a91-35a5-4ce4-a7c2-36389cf1d4c4.jpeg');
export const pro5 = require('../../New/Ashequr Rahman.jpeg');
export const pro6 = require('../../New/Beacon.jpeg');
export const pro7 = require('../../New/Mane Omsy Pass Pic.jpeg');

/* Testimonials data */
const testimonials = [
  {
    name: 'Shoeb Khan',
    feedback: 'The training at Genius Institute is top-notch. The instructors are incredibly knowledgeable and approachable. The hands-on experience and placement assistance helped me land my dream job and I couldn’t be happier with the outcome!',
    image: pro3,
  },
  {
    name: 'Ismail Ansari',
    feedback: 'The course was affordable and the instructors were really helpful. The practical sessions were invaluable in understanding real-world applications. I appreciate the personalized guidance and support throughout the course.',
    image: pro2,
  },
  {
    name: 'Asif Shaikh',
    feedback: 'I am grateful for the hands-on projects and comprehensive placement support. The course provided me with a solid foundation and practical skills that made a significant impact on my career. The learning experience was truly transformative.',
    image: pro1,
  },
  {
    name: 'Rubi Bano',
    feedback: 'Amazing faculty and a well-structured course. The curriculum was thorough, and I gained a deep understanding of Computer Application. The practical labs were particularly beneficial in applying the concepts learned in class.',
    image: pro4,
  },
  {
    name: 'Faisal Qamruzzama',
    feedback: 'The fees are reasonable, and the value for money is outstanding. The course exceeded my expectations with its quality content and expert instructors. I highly recommend Genius Institute for anyone looking to advance their career.',
    image: pro5,
  },
  {
    name: 'Imran Chaudhary',
    feedback: 'The Electrical & Electronics course was detailed and well-taught. The instructors were experienced and provided clear explanations. The hands-on practical sessions were the highlight, offering valuable real-world experience.',
    image: pro6,
  },
  {
    name: 'Ansari Hafeez',
    feedback: 'My experience with the BMS course was exceptional. The projects and assignments were well-designed, helping me to better understand the concepts. The support from the faculty and the resources provided were excellent.',
    image: pro7,
  },
];

/* Styled component for Testimonial Card */
const TestimonialCard = styled(Paper)(({ theme, selected, position }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  borderRadius: '16px',
  boxShadow: '0 10px 20px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.06)',
  transition: 'transform 0.3s ease, background-color 0.3s ease',
  backgroundColor: '#fff',
  transform: selected ? 'scale(1.1)' : position === 'side' ? 'scale(0.95)' : 'scale(1)',
  margin: theme.spacing(2),
  maxWidth: '85%',  // Use percentage to ensure responsiveness
  minWidth: 200,
  minHeight: 300,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
}));

/* Styled component for Testimonial Image */
const TestimonialImage = styled('img')(({ theme }) => ({
  borderRadius: '50%',
  width: 80,
  height: 80,
  objectFit: 'cover',
  marginBottom: theme.spacing(1),
  display: 'block',
  margin: '0 auto', // Center the image
}));

/* Styled component for Testimonial Name */
const TestimonialName = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 2),
  borderRadius: '20px',
  backgroundColor: '#1565C0',
  color: '#fff',
  boxShadow: '0 9px 18px rgba(0,0,0,0.12), 0 5px 7px rgba(0,0,0,0.05)',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '0.9rem',
}));

/* Styled component for Testimonials Container */
const TestimonialsContainer = styled('div')(({ theme }) => ({
  width: '100%', // Ensure the container takes full width
  maxWidth: '1200px',
  margin: '0 auto',
  padding: theme.spacing(4, 0),
  overflow: 'hidden', // Prevent overflow
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 0),
  },
}));

/* Styled component for Testimonial Text */
const TestimonialText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1rem',
  },
}));

/* Testimonials Component */
const Testimonials = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,
    autoplaySpeed: 3000, // 2 seconds
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
    beforeChange: (current, next) => setActiveIndex(next),
  };

  return (
    <TestimonialsContainer>
      {/* Section Title */}
      <Typography variant="h4" align="center" gutterBottom>
        What People Say About Us
      </Typography>
      {/* Slider Component */}
      <Slider {...sliderSettings}>
        {testimonials.map((testimonial, index) => {
          const isSelected = index === activeIndex;
          const position = Math.abs(index - activeIndex) === 1 ? 'side' : 'other';
          return (
            <TestimonialCard key={index} selected={isSelected} position={position}>
              <TestimonialImage src={testimonial.image} alt={testimonial.name} />
              <TestimonialText variant="body1" color="textSecondary" gutterBottom>
                {testimonial.feedback}
              </TestimonialText>
              <TestimonialName>
                {testimonial.name}
              </TestimonialName>
            </TestimonialCard>
          );
        })}
      </Slider>
    </TestimonialsContainer>
  );
};

export default Testimonials;
